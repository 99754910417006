.app-header { background: $white; overflow-x: hidden; padding: 10px 0; }
.navbar-toggler { position: absolute; right: 0; top: 50%; transform: translateY(-50%); display: none; border-radius: 5px; width: 36px; padding: 6px; cursor: pointer; background: $theme-color; }


@include responsive(sm) {
  .navbar { position: relative; }
  .navbar-toggler { display: block; }
  .navbar-links { position: fixed; top: 66px; right: 0; width: 100%; background: $black; z-index: 99; margin: 0; padding: 5px 0; transition: all ease-in-out 0.3s;
    &.menu-collapse { margin-right: -100%; width: 0; overflow: hidden; }
  }
  .nav-link { padding: 12px 20px; color: $white; display: block; margin: 0;
    &:hover { background: rgba($color: $white, $alpha: 0.25); color: $theme-color; }
  }
}