// Variable overrides
$body-color: rgb(82, 40, 40);
$theme-colors: (
  "primary": #5386ef,
  "danger": #ff4136,
  "warning": #EFCA2B,
  "info": #5386ef,

);
$theme-color: #337091;
$theme-blue: #5D7EDF;
$theme-pink: #FF4D84;
$theme-yellow: #FFC400;
$theme-magenta: #0ED3B9;
$theme-orange: #FF8D1C;
$theme-black: #484848;
$theme-black2: #333333;
$theme-bg: #F2F3F8;
$white: #ffffff;
$black: #000000;

// Gray
$color1: #8D8E9F;
$color2: #707070;
$color3: #A4B4BD;
$color4: #D5E2EA;
$color5: #E2F1F9;

$success: #089B55;
$danger: #E5664B;
$warning: #E5B64B;
$info: #448FB8;

// media quary
$lg: 1499px;
$tab: 1024px;
$md: 991px;
$sm: 767px;
$xs: 575px;