// Here you can add other styles
span.logo-img {
    display: inline-block;
    vertical-align: middle;
    width: 100px;
    margin-bottom: 15px;
    img {
        width: 100%;
    }
  }


  .table th, .table td {
    padding: 0.75rem;
    vertical-align:middle;
    border-top: 1px solid;
    border-top-color: #d8dbe0;
  }
  // logout toastr button style
  .table thead th{
    vertical-align:middle;
  }

.navbar-brand {
  padding : 7,
  background-color = #d8dbe0 !important;
}
.share-btn {
  height:50px;
  // width:50%
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}